import React from 'react'
import ReactDOM from 'react-dom'

import { build } from './infrastructure/config'
import App from './application/App'

ReactDOM.render(
  build(<App />),
  document.getElementById('root'),
)
