import React from 'react'
import propertiesService from '../services/propertiesService'

const PropertiesContext = React.createContext()
PropertiesContext.displayName = 'PropertiesContext'

function PropertiesProvider(props) {
  const [properties, setProperties] = React.useState([])

  const retrieveProperties = React.useCallback(communityId => {
    propertiesService.retrieveProperties(communityId).then(properties => {
      setProperties(properties)
      return properties
    })
  }, [])

  const retrieveProperty = React.useCallback(
    async propertyId => {
      const property = properties.find(property => property.id === propertyId)
      if (property) {
        return property
      }
      return await propertiesService.retrieveOwner(propertyId)
    },
    [properties],
  )

  const value = React.useMemo(
    () => ({
      properties,
      retrieveProperties,
      retrieveProperty,
    }),
    [properties, retrieveProperties, retrieveProperty],
  )
  return <PropertiesContext.Provider value={value} {...props} />
}

function useProperties() {
  const context = React.useContext(PropertiesContext)
  if (context === undefined) {
    throw new Error('useProperties must be used within a PropertiesProvider')
  }
  return context
}

export { PropertiesProvider, useProperties }
