import communitiesRepository from '../repositories/communitiesRepository.js'

export default {
  async retrieveCommunities() {
    const communities = await communitiesRepository.retrieveCommunities()
    return communities
  },

  async retrieveCommunity(communityId) {
    return await communitiesRepository.retrieveCommunity(communityId)
  },
}
