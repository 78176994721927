import React, { useContext, useState, useMemo, useCallback } from 'react'
import communitiesService from '../services/communitiesService'

const CommunitiesContext = React.createContext()
CommunitiesContext.displayName = 'CommunitiesContext'

function CommunitiesProvider(props) {
  const [communities, setCommunities] = useState([])
  const [community, setCommunity] = useState(null)

  const retrieveCommunities = useCallback(async () => {
    setCommunity(null)
    const communities = await communitiesService.retrieveCommunities()
    setCommunities(communities)
    return communities
  }, [])

  const retrieveCommunity = useCallback(
    async communityId => {
      const community =
        communities.find(c => c.id === communityId) ||
        (await communitiesService.retrieveCommunity(communityId))
      setCommunity(community)
      return community
    },
    [communities],
  )

  const value = useMemo(
    () => ({ communities, community, retrieveCommunities, retrieveCommunity }),
    [communities, community, retrieveCommunities, retrieveCommunity],
  )

  return <CommunitiesContext.Provider value={value} {...props} />
}

function useCommunities() {
  const context = useContext(CommunitiesContext)
  if (context === undefined) {
    throw new Error('useCommunities must be used within a CommunitiesProvider')
  }
  return context
}

export { CommunitiesProvider, useCommunities }
