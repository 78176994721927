import React, { useState } from 'react'
import * as authClient from 'application/authentication/repositories/AuthRepository'

const AuthContext = React.createContext()
AuthContext.displayName = 'AuthContext'

function AuthProvider(props) {
  const [user, setUser] = useState(null)

  const login = React.useCallback(
    form => authClient.login(form).then(setUser),
    [setUser],
  )

  const logout = React.useCallback(async () => {
    setUser(null)
    await authClient.logout()
  }, [setUser])

  const retrieveUser = React.useCallback(
    () => authClient.retrieveUser().then(setUser),
    [setUser],
  )

  const isLoggedIn = React.useCallback(() => {
    const logged = authClient.isLoggedIn()
    return logged
  }, [])

  const value = React.useMemo(
    () => ({ user, login, logout, retrieveUser, isLoggedIn }),
    [user, login, logout, retrieveUser, isLoggedIn],
  )

  return <AuthContext.Provider value={value} {...props} />
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export { AuthProvider, useAuth }
