import { client } from 'infrastructure/apiClient'

export default {
  retrieveProperties(communityId) {
    return client(`/properties/?community=${communityId}`)
  },
  retrieveProperty(propertyId) {
    return client(`/properties/${propertyId}/`)
  },
}
