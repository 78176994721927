import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

function FullPage(props) {
  const classes = useStyles()
  return <div className={classes.root} {...props} />
}

export default FullPage
