import { client } from 'infrastructure/apiClient'
import Cookies from 'js-cookie'

let csrfToken

function getCsrfToken() {
  return csrfToken
}

async function setCsrfToken() {
  await client('/auth/set-csrf-token/')
  csrfToken = Cookies.get('csrftoken')
}

function retrieveUser() {
  return client('/users/me/')
}

async function login({ email, password }) {
  await client('/auth/login/', {
    data: { email, password },
  })
  setCsrfToken()
  const user = await retrieveUser()
  return user
}


async function logout() {
  return await client('/auth/logout/', { method: 'POST' })
}

async function isLoggedIn() {
  setCsrfToken()
  const { isAuthenticated } = await client('/auth/status/')
  return isAuthenticated
}

export {
  retrieveUser,
  login,
  logout,
  isLoggedIn,
  getCsrfToken,
}
