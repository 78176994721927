import propertyRepository from 'application/properties/repositories/propertiesRepository.js'


export default {
  async retrieveProperties(communityId) {
    return await propertyRepository.retrieveProperties(communityId)
  },

  async getPropertyQuery(propertyId) {
    return await propertyRepository.retrieveProperty(propertyId)
  },
}
