import React from 'react'
import Spinner from 'lib/Spinner'
import FullPage from './FullPage'


function FullPageSpinner() {
  return (
    <FullPage>
      <Spinner />
    </FullPage>
  )
}

export default FullPageSpinner