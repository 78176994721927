import { client } from 'infrastructure/apiClient'

export default {
  createMeeting(meeting) {
    const config = {
      method: 'POST',
      data: { ...meeting },
    }
    return client('/meetings/', config)
  },
  retrieveMeetings(communityId) {
    return client(`/meetings/?community=${communityId}`)
  },
  retrieveMeeting(meetingId) {
    return client(`/meetings/${meetingId}/`)
  },
  updateMeeting(meetingId, data) {
    const config = {
      method: 'PUT',
      data: data,
    }
    return client(`/meetings/${meetingId}/`, config)
  },
  deleteMeeting(meetingId) {
    return client(`/meetings/${meetingId}/`, { method: 'DELETE' })
  },
  meetingCall(meetingId, data) {
    const config = {
      responseIsFile: true,
      method: 'POST',
      data,
    }
    return client(`/meetings/${meetingId}/call/`, config)
  },
}
