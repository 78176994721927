import repository from '../repositories/meetingsRepository'

export default {
  async retrieveMeetings(communityId) {
    const meetings = await repository.retrieveMeetings(communityId)
    return meetings
  },
  async retrieveMeeting(meetingId) {
    const meeting = await repository.retrieveMeeting(meetingId)
    return meeting
  },
  async updateMeeting({ id, ...meeting }) {
    const updatedMeeting = await repository.updateMeeting(id, meeting)
    return updatedMeeting
  },
  async createMeeting(data) {
    const meeting = await repository.createMeeting(data)
    return meeting
  },
  async deleteMeeting(data) {
    return await repository.deleteMeeting(data)
  },
  async meetingCall(meetingId, payload) {
    return await repository.meetingCall(meetingId, payload)
  },
}
