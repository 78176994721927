import React, { useContext, useState, useCallback, useMemo } from 'react'
import meetingsService from '../services/meetingsService'

const MeetingsContext = React.createContext()
MeetingsContext.displayName = 'MeetingsContext'

function MeetingsProvider(props) {
  const [meetings, setMeetings] = useState([])

  const retrieveMeetings = useCallback(async communityId => {
    const meetings = await meetingsService.retrieveMeetings(communityId)
    setMeetings(meetings)
  }, [])

  const retrieveMeeting = useCallback(
    async meetingId => {
      const meeting = meetings.find(meeting => meeting.id === meetingId)
      if (meeting) {
        return meeting
      }
      return await meetingsService.retrieveMeeting(meetingId)
    },
    [meetings],
  )

  const createMeeting = useCallback(async payload => {
    const meeting = await meetingsService.createMeeting(payload)
    setMeetings(meetings => [...meetings, meeting])
  }, [])

  const updateMeeting = React.useCallback(
    async meeting => {
      const updatedMeeting = await meetingsService.updateMeeting(meeting)
      const updatedMeetings = meetings.map(meeting =>
        meeting.id === updatedMeeting.id ? updatedMeeting : meeting,
      )
      setMeetings(updatedMeetings)

      return updatedMeeting
    },
    [meetings],
  )

  const deleteMeeting = useCallback(
    async meetingId => {
      await meetingsService.deleteMeeting(meetingId)
      setMeetings(meetings.filter(meeting => meeting.id !== meetingId))
    },
    [meetings],
  )

  const value = useMemo(
    () => ({
      meetings,
      retrieveMeetings,
      retrieveMeeting,
      createMeeting,
      updateMeeting,
      deleteMeeting,
      meetingCall: meetingsService.meetingCall,
    }),
    [
      meetings,
      retrieveMeetings,
      retrieveMeeting,
      createMeeting,
      updateMeeting,
      deleteMeeting,
    ],
  )
  return <MeetingsContext.Provider value={value} {...props} />
}

function useMeetings() {
  const context = useContext(MeetingsContext)
  if (context === undefined) {
    throw new Error('useMeetings must be used within a MeetingsProvider')
  }
  return context
}

export { MeetingsProvider, useMeetings }
