import React from 'react'
import { AuthProvider } from 'application/authentication/context/authContext'
import { OwnersProvider } from 'application/owners/context/ownersContext'
import { MeetingsProvider } from 'application/meetings/context/meetingsContext'
import { PropertiesProvider } from 'application/properties/context/propertiesContext'
import { LocationProvider } from 'application/locations/context/locationsContext'
import { CommunitiesProvider } from 'application/communities/context/communitiesContext'

export const buildProviders = app => (
  <AuthProvider>
    <LocationProvider>
      <CommunitiesProvider>
        <OwnersProvider>
          <PropertiesProvider>
            <MeetingsProvider>{app}</MeetingsProvider>
          </PropertiesProvider>
        </OwnersProvider>
      </CommunitiesProvider>
    </LocationProvider>
  </AuthProvider>
)
