import React from 'react'
import locationsService from '../services/locationsService'

const LocationsContext = React.createContext()
LocationsContext.displayName = 'LocationsContext'

function LocationProvider(props) {
  const [countries, setCountries] = React.useState([])

  const retrieveCountries = React.useCallback(() => {
    locationsService.retrieveCountries().then(countries => {
      setCountries(countries)
      return countries
    })
  }, [])

  const retrieveCountryName = React.useCallback(
    country => (country ? countries[country] : ''),
    [countries],
  )

  const value = React.useMemo(
    () => ({
      countries,
      retrieveCountries,
      retrieveCountryName,
    }),
    [countries, retrieveCountries, retrieveCountryName],
  )
  return <LocationsContext.Provider value={value} {...props} />
}

function useLocations() {
  const context = React.useContext(LocationsContext)
  if (context === undefined) {
    throw new Error('useLocation must be used within a LocationProvider')
  }
  return context
}

export { LocationProvider, useLocations }
