import React from 'react'
import { useAuth } from 'application/authentication/context/authContext'
import FullPageSpinner from 'lib/FullPageSpinner'

const AuthenticatedApp = React.lazy(() => import('./AuthenticatedApp'))
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'))

function App() {
  const { user, retrieveUser, isLoggedIn } = useAuth()
  const [isLoading, setIsLoading] = React.useState(false)

  React.useLayoutEffect(() => {
    async function bootstrap() {
      setIsLoading(true)
      const logged = await isLoggedIn()
      if (logged) {
        await retrieveUser()
      }
      setIsLoading(false)
    }
    bootstrap()
  }, [retrieveUser, isLoggedIn])

  if (isLoading) {
    return <FullPageSpinner />
  }

  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  )
}

export default App
