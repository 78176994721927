import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from 'locale/en.json'
import es from 'locale/es.json'

const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    debug: false,
    fallbackLng: 'en',
    whitelist: ['en', 'es'],

    interpolation: {
      escapeValue: false,
    },
  })

export const LANGUAGES = ['en', 'es']
export const LANGUAGE_CHOICES = LANGUAGES.map(code => ({
  value: code,
  label: code.toUpperCase(),
}))
export const buildI18n = app => app
export default i18n
