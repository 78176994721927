import React from 'react'
import OwnersService from '../services/OwnersService'

const OwnersContext = React.createContext()
OwnersContext.displayName = 'OwnerContext'

function OwnersProvider(props) {
  const [owners, setOwners] = React.useState([])

  const retrieveOwners = React.useCallback(
    communityId => OwnersService.retrieveOwners(communityId).then(setOwners),
    [],
  )

  const retrieveOwner = React.useCallback(
    async ownerId => {
      const owner = owners.find(owner => owner.id === ownerId)
      if (owner) {
        return owner
      }
      return await OwnersService.retrieveOwner(ownerId)
    },
    [owners],
  )

  const createOwner = React.useCallback(
    owner =>
      OwnersService.createOwner(owner).then(newOwner => {
        setOwners(owners => [newOwner, ...owners])
        return newOwner
      }),
    [],
  )

  const updateOwner = React.useCallback(
    async owner => {
      const updatedOwner = await OwnersService.updateOwner(owner)
      const updatedOwners = owners.map(owner =>
        owner.id === updatedOwner.id ? updatedOwner : owner,
      )
      setOwners(updatedOwners)

      return updatedOwner
    },
    [owners],
  )

  const deleteOwner = React.useCallback(async ownerId => {
    const deletedOwner = await OwnersService.deleteOwner(ownerId)
    setOwners(owners => owners.filter(owner => owner.id !== deletedOwner.id))
    return deletedOwner
  }, [])

  const value = React.useMemo(
    () => ({
      owners,
      retrieveOwners,
      retrieveOwner,
      createOwner,
      updateOwner,
      deleteOwner,
    }),
    [
      owners,
      retrieveOwners,
      retrieveOwner,
      createOwner,
      updateOwner,
      deleteOwner,
    ],
  )
  return <OwnersContext.Provider value={value} {...props} />
}

function useOwners() {
  const context = React.useContext(OwnersContext)
  if (context === undefined) {
    throw new Error('useOwners must be used within a OwnersProvider')
  }
  return context
}

export { OwnersProvider, useOwners }
