import repository from '../repositories/OwnersRepository'

export default {
  async retrieveOwners(communityId) {
    const owner = await repository.retrieveOwners(communityId)
    return owner
  },
  async retrieveOwner(ownerId) {
    const owner = await repository.retrieveOwner(ownerId)
    return owner
  },
  async createOwner(owner) {
    const newOwner = await repository.createOwner(owner)
    return newOwner
  },
  async updateOwner({ id, ...owner }) {
    const updatedOwner = await repository.updateOwner(id, owner)
    return updatedOwner
  },
  async deleteOwner(ownerId) {
    return await repository.deleteOwner(ownerId)
  },
}
