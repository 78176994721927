import { createMuiTheme, fade } from '@material-ui/core'


const PRIMARY_COLOR = '#2980b9'

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#f6f7f9',
    },
    primary: {
      main: PRIMARY_COLOR,
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiFormControl: {
      marginNormal: {
        marginBottom: '16px',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiAlert: {
      root: {
        padding: '9px 16px',
      },
    },
    MuiTableRow: {
      root: {
        '&$selected, &$selected:hover': {
          backgroundColor: fade(PRIMARY_COLOR, 0.08),
        },
      },
    },
  },
  props: {
    MuiButton: {
      variant: 'contained',
    },
    MuiTextField: {
      variant: 'outlined',
      margin: 'normal',
    },
    MuiCheckbox: {
      color: 'primary',
    },
  },
})

export default theme
