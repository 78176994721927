import {
  logout,
  getCsrfToken,
} from 'application/authentication/repositories/AuthRepository'
import { camelizeKeys, decamelizeKeys } from 'humps'
import i18n from 'infrastructure/config/configI18n'

const API_URL = '/api'
const NO_CONTENT = 204

function reloadPage() {
  window.location.assign(window.location)
}

async function client(
  endpoint,
  {
    data,
    method,
    transform = true,
    responseIsFile = false,
    ...customConfig
  } = {},
) {
  const transformedData = transform ? decamelizeKeys(data) : data

  const body = transformedData ? JSON.stringify(transformedData) : undefined

  const config = {
    method: method || (data ? 'POST' : 'GET'),
    body,
    ...customConfig,
    credentials: 'include',
    headers: {
      'X-CSRFToken': getCsrfToken(),
      'Content-Type': body ? 'application/json' : undefined,
      'Accept-language': i18n.language,
    },
  }

  return window.fetch(`${API_URL}${endpoint}`, config).then(async response => {
    if (response.status === NO_CONTENT) {
      return response
    }

    if (response.ok && responseIsFile) {
      return await response.blob()
    }

    const data = await response.json()
    const transformedData = transform ? camelizeKeys(data) : data
    if (response.ok) {
      return transformedData
    }
    if (response.status === 401) {
      logout()
      reloadPage()
    }

    return Promise.reject(transformedData)
  })
}

export { client }
