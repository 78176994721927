import { client } from 'infrastructure/apiClient'

export default {
  retrieveOwners(communityId) {
    return client(`/owners/?community=${communityId}`)
  },
  retrieveOwner(ownerId) {
    return client(`/owners/${ownerId}/`)
  },
  createOwner(data) {
    return client('/owners/', { data })
  },
  updateOwner(ownerId, data) {
    return client(`/owners/${ownerId}/`, { method: 'PUT', data })
  },
  deleteOwner(ownerId) {
    return client(`/owners/${ownerId}/`, { method: 'DELETE' })
  },
}
