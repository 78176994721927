import { client } from 'infrastructure/apiClient'

export default {
  retrieveCommunities() {
    return client('/communities/')
  },
  retrieveCommunity(communityId) {
    return client(`/communities/${communityId}/`)
  },
}
