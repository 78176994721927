import React from 'react'
import theme from 'styles/theme'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core'

export const buildTheme = app => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {app}
  </ThemeProvider>
)
